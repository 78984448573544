/* Header.css */
header {
    background-color: #333;
    color: white;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  nav ul {
    list-style: none;
    display: flex;
    align-items: center;
  }
  
  nav ul li {
    margin-right: 20px;
  }
  
  /* Media queries for responsiveness */
  @media (max-width: 768px) {
    .menu-toggle {
      display: flex;
      flex-direction: column;
      cursor: pointer;
    }
  
    .menu-toggle span {
      width: 30px;
      height: 3px;
      background-color: white;
      margin: 3px 0;
    }
  
    header {
      flex-direction: column;
    }
  
    .logo {
      margin-left: 0;
    }
  
    nav ul {
      display: none; /* Hide the menu by default */
      width: 100%;
      margin-top: 20px;
      text-align: center;
    }
  
    nav.menu-open ul {
      display: flex; /* Display the menu when the menu is open */
      flex-direction: column; /* Stack items vertically when menu is open */
    }
  
    nav ul li {
      margin: 10px 0;
    }
  }
  